/* frontend/src/components/Spinner.css */

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #66bb6a; /* Light green */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
