/* frontend/src/App.css */

.App {
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 20px;
  background-color: #e0f7fa; /* Light teal background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  color: #2e7d32; /* Dark green */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

p {
  font-size: 1.2em;
  color: #1b5e20; /* Forest green */
}

form {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type='text'] {
  width: 90%;
  padding: 12px;
  font-size: 1em;
  border: 2px solid #66bb6a; /* Light green border */
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;
}

input[type='text']:focus {
  border-color: #388e3c; /* Darker green on focus */
}

button {
  padding: 12px 24px;
  margin-top: 10px;
  font-size: 1em;
  background-color: #ffca28; /* Golden button */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #ffa000; /* Darker gold on hover */
}

.response {
  margin-top: 30px;
  text-align: left;
  display: inline-block;
  max-width: 600px;
  background-color: #f1f8e9; /* Light green background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.response h2 {
  margin-bottom: 10px;
  color: #33691e; /* Olive green */
}

.response p {
  font-size: 1.2em;
  line-height: 1.5em;
  color: #2e7d32; /* Dark green */
}

.history {
  margin-top: 40px;
  text-align: left;
  display: inline-block;
  max-width: 600px;
  width: 100%;
}

.history h2 {
  color: #558b2f; /* Medium green */
}

.history ul {
  list-style-type: none;
  padding: 0;
}

.history li {
  background-color: #c8e6c9; /* Pale green */
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.history li strong {
  color: #1b5e20; /* Forest green */
}
